import React, { createContext, useContext } from "react";

import { Config } from "../../types";

const ConfigContext = createContext<Config | null>(null);

export default function ConfigProvider({
  children,
  config,
}: {
  children?: React.ReactChild;
  config: Config;
}): JSX.Element | null {
  const configContext = config;

  if (!children) {
    return null;
  }

  return (
    <ConfigContext.Provider value={configContext}>
      {children}
    </ConfigContext.Provider>
  );
}

export const useConfig = (): Config | undefined => useContext(ConfigContext);
