import { Branding, Config, DashboardConfig, Group } from "../../types";
import React, { useEffect, useState } from "react";

import ConfigProvider from "../../helpers/config/config-provider";
import buildConfig from "../../config.json";

const config: Config = {
  id: "INVALID",
  STUDIO_URI: "INVALID",
  STUDIO_S3_BUCKET: "INVALID",
  dashboards: [],
  title: "Financial Canvas Studio",
  branding: "sciurus",
};

export default config;

export const useStudioFeatureFlag = (flag: string) => {
  if (!("STUDIO_FEATURE_FLAGS" in config)) {
    return false;
  }

  if (!(config.STUDIO_FEATURE_FLAGS && flag in config.STUDIO_FEATURE_FLAGS)) {
    return false;
  }

  return config["STUDIO_FEATURE_FLAGS"][flag];
};

export function getBranding(): Branding {
  return buildConfig.branding[config.branding];
}

export function getGroups(): Group[] {
  return config.groups.map((id) => ({
    id: id,
    ...buildConfig.groups[id],
  }));
}

export function getDashboardConfigs() {
  return config.dashboards.map(
    (dashboard) => buildConfig.dashboards[dashboard]
  );
}

export function getDashboardConfig(dashboardName?: string): DashboardConfig {
  return (
    config.dashboards
      .map((dashboard) => buildConfig.dashboards[dashboard])
      .find((d) => d.value === dashboardName) || {
      value: "",
      label: undefined,
      source: "",
      component: "",
      keys: [],
    }
  );
}

export function getColorScheme() {
  const colors: { [index: string]: string } = {
    body: "#ddeeff",
    box: "#cde",
    border: "#cde",
    header: "#cde",
    panel: "#ddeeff",
  };

  const cfgColors = config.colorScheme;

  if (!cfgColors || Object.keys(cfgColors).length === 0) {
    return colors;
  }

  Object.keys(cfgColors).forEach((key) => {
    colors[key] = cfgColors[key];
  });

  return colors;
}

export type ConfigLoaderProps = {
  children: React.ReactChild;
};

/** Defers rendering its children until configuration is available. */
export const ConfigLoader = ({ children }: ConfigLoaderProps) => {
  const [localConfig, setLocalConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchConfig() {
      const result = await fetch("/config.json", { cache: "no-cache" });
      const newConfig = await result.json();
      for (let prop in localConfig) {
        delete config[prop];
      }

      for (let prop in newConfig) {
        config[prop] = newConfig[prop];
      }

      setIsLoading(false);
      setLocalConfig(newConfig);
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  return <ConfigProvider config={localConfig}>{children}</ConfigProvider>;
};
