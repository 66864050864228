import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "./i18n";

import * as Sentry from "@sentry/browser";

import React, { Suspense } from "react";

import { ConfigLoader } from "./components/config-loader";
import ReactDOM from "react-dom";

fetch("/config.json")
  .then((response) => response.json())
  .then((config) => {
    // Enable Sentry reporting
    if (
      config.STUDIO_SENTRY_AUTH &&
      config.STUDIO_SENTRY_PROJECT_ID &&
      process.env.NODE_ENV === "production"
    ) {
      Sentry.init({
        dsn: `https://${config.STUDIO_SENTRY_AUTH}@o398312.ingest.sentry.io/${config.STUDIO_SENTRY_PROJECT_ID}`,
      });
    }
  });

const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <ConfigLoader>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </ConfigLoader>,
  document.getElementById("root")
);
